import { useRef, useEffect, useState } from "react"
import { addPropertyControls, ControlType } from "framer"
import { motion, useScroll, useTransform, useSpring } from "framer-motion"
import Icon from "./Assets/Icon.tsx"

const EachWord = ({
    word,
    starting,
    ending,
    startOpacity,
    endOpacity,
    progress,
    stiffness,
    damping,
    highlightColor,
    isItalic,
    linkUrl,
    isHovered,
    onMouseEnter,
    onMouseLeave,
    isHoverable,
    iconSize, // Accept the iconSize prop
}) => {
    const sizeMap = {
        xss: 12,
        xs: 16,
        sm: 18,
        base: 24,
        xl: 40,
        "2xl": 64,
        mega: 96,
    }

    // Dynamically calculate icon size
    const calculatedIconSize = sizeMap[iconSize] || sizeMap.base

    const progressVal = useTransform(
        progress,
        [starting, ending],
        [startOpacity, endOpacity]
    )
    const smoothProgress = useSpring(progressVal, { stiffness, damping })

    const wordStyles = {
        display: "inline-flex",
        opacity: smoothProgress,
        willChange: "opacity",
        color: highlightColor,
        fontStyle: isItalic ? "italic" : "normal",
        cursor: linkUrl && isHoverable ? "pointer" : "default",
        transition: "all 0.1s ease", // Smooth transition for text
    }

    const iconWrapperStyles = {
        overflow: "hidden", // Hide the icon when width is 0
        display: "inline-flex", // Keep the inline layout
        // alignItems: "flex-end",
        width: isHovered ? `${calculatedIconSize}px` : "0px", // Smoothly transition width of the icon
        transition: [
            "width 0.3s ease 100ms",
            "opacity 0.3s ease 100ms",
            "visibility 0.1s",
        ], // Control width transition
    }

    const iconStyles = {
        opacity: isHovered ? 1 : 0, // Fade in/out the icon
        visibility: isHovered ? "visible" : "hidden",
        transition: "opacity 0.3s ease", // Control opacity transition
    }

    const handleClick = () => {
        if (linkUrl && isHoverable) {
            window.location.href = linkUrl
        }
    }

    // Check if the word has a punctuation mark at the end (e.g., ".")
    const wordWithoutPunctuation = word.replace(/[.,!?;:]$/, "")
    const punctuation = word.slice(wordWithoutPunctuation.length)

    return (
        <span
            style={{ display: "inline-flex", alignItems: "baseline" }}
            onClick={handleClick}
            onMouseEnter={isHoverable ? onMouseEnter : undefined}
            onMouseLeave={isHoverable ? onMouseLeave : undefined}
        >
            {isHoverable && (
                <motion.span style={iconWrapperStyles}>
                    <motion.span style={iconStyles}>
                        <Icon
                            icon="arrow-right"
                            size={iconSize} // Use the iconSize prop here
                            borderColor={highlightColor}
                        />
                    </motion.span>
                </motion.span>
            )}
            <motion.span style={wordStyles}>
                {wordWithoutPunctuation}
            </motion.span>

            <motion.span style={wordStyles}>{punctuation}</motion.span>
        </span>
    )
}

export default function Reveal(props) {
    const text = props.text
    const words = text.split(" ") // Split by spaces
    const totalWords = words.length
    const ref = useRef(null)
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: [`start ${props.startOffset}`, `end 0.70`],
    })

    const [hoveredWord, setHoveredWord] = useState(null)

    const handleMouseEnter = (idx) => {
        setHoveredWord(idx)
    }

    const handleMouseLeave = () => {
        setHoveredWord(null)
    }

    const cleanWord = (word) => {
        return word.replace(/[^a-zA-Z]/g, "").toLowerCase()
    }

    return (
        <div>
            <p
                ref={ref}
                style={{
                    ...props.font,
                    color: props.textColor,
                    margin: 0,
                    padding: 0,
                }}
            >
                {words.map((word, idx) => {
                    const starting = idx / totalWords
                    const ending = (idx + 1) / totalWords
                    let highlightColor = null
                    let isItalic = false
                    let linkUrl = null
                    let isHoverable = false

                    const cleanedWord = cleanWord(word)

                    if (cleanedWord === "attract") {
                        highlightColor = "#2877FF"
                        isItalic = true
                        linkUrl = "https://www.leat.com/platform/attract"
                        isHoverable = true
                    } else if (cleanedWord === "engagement") {
                        highlightColor = "#FA7000"
                        isItalic = true
                        linkUrl = "https://www.leat.com/platform/engage"
                        isHoverable = true
                    } else if (cleanedWord === "growth") {
                        highlightColor = "#22C55E"
                        isItalic = true
                        linkUrl = "https://www.leat.com/platform/grow"
                        isHoverable = true
                    }

                    return (
                        <span key={idx}>
                            <EachWord
                                word={word}
                                starting={starting}
                                ending={ending}
                                startOpacity={props.startOpacity}
                                endOpacity={props.endOpacity}
                                progress={scrollYProgress}
                                stiffness={props.stiffness}
                                damping={props.damping}
                                highlightColor={highlightColor}
                                isItalic={isItalic}
                                linkUrl={linkUrl}
                                isHovered={hoveredWord === idx}
                                onMouseEnter={() => handleMouseEnter(idx)}
                                onMouseLeave={handleMouseLeave}
                                isHoverable={isHoverable}
                                iconSize={props.iconSize} // Pass the iconSize prop here
                            />
                            {idx < totalWords - 1 && " "}{" "}
                            {/* Add space between words */}
                        </span>
                    )
                })}
            </p>
        </div>
    )
}

addPropertyControls(Reveal, {
    text: {
        title: "Text",
        type: ControlType.String,
        defaultValue:
            "We help to attract customer data, put data into action with our engagement suite and accelerate your growth.",
        displayTextArea: true,
    },
    font: {
        type: "font",
        controls: "extended",
    },
    textColor: {
        type: ControlType.Color,
    },
    startOffset: {
        title: "Start Offset",
        type: ControlType.Number,
        defaultValue: 0.75,
        min: 0,
        max: 1,
        step: 0.01,
        description:
            "Defines the scroll position at which the text animation starts. A value of 0 means the animation starts at the top of the viewport, and a value of 1 means it starts at the bottom.",
    },
    stiffness: {
        title: "Stiffness",
        type: ControlType.Number,
        defaultValue: 100,
        min: 0,
        max: 1000,
        step: 1,
        description: "Controls the stiffness of the spring animation.",
    },
    damping: {
        title: "Damping",
        type: ControlType.Number,
        defaultValue: 20,
        min: 0,
        max: 100,
        step: 1,
        description: "Controls the damping of the spring animation.",
    },
    startOpacity: {
        title: "Start Opacity",
        type: ControlType.Number,
        defaultValue: 0.1,
        min: 0,
        max: 1,
        step: 0.01,
        description: "Start opacity value for the useTransform function.",
    },
    endOpacity: {
        title: "End Opacity",
        type: ControlType.Number,
        defaultValue: 1,
        min: 0,
        max: 1,
        step: 0.01,
        description: "End opacity value for the useTransform function.",
    },
    iconSize: {
        type: ControlType.Enum,
        title: "Icon size",
        options: ["xss", "xs", "sm", "base", "xl", "2xl", "mega"],
        optionTitles: ["XXS", "XS", "Small", "Base", "XL", "2XL", "Mega"],
        defaultValue: "2xl",
    },
})
